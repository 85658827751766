//Suggested location to add your overrides so that migration would be easy by just updating the SASS folder in the future

.p-breadcrumb {
    border: none;
    border-bottom: 1px solid rgba(145, 158, 171, 0.12);
    border-radius: 2;
    padding: 1rem;
    background-color: rgb(255, 255, 255);
    color: rgb(33, 43, 54);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-image: none;
    position: relative;
    margin-bottom: 20px;
  }
  .p-breadcrumb ul li .p-menuitem-link {
    transition: box-shadow 0.2s;
    border-radius: 0;
  }
   
  .header-white-logo {
    color: var(--primary-color);
  }
  .action-bottons {
    .p-splitbutton-defaultbutton{
      display: none;  
    }
    .p-splitbutton-menubutton{
      border-radius: 2px;
      outline: none;
    }
    .p-button {
      background: transparent;
      border: none;
      color: var(--primary-color);
      outline: none;
      &:hover {
        background: transparent;
        border: none;
        outline: none;
        color: var(--primary-color);
      }
    }
  }
   
